.newsletter-card{
    background: linear-gradient(215.36deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-radius: 10px;
}
.newsletter-card img{
    width: 25px;
    position: relative;
    margin-top: -32.5px;
}
.newsletter-card-title{
    font-weight: 500;
    font-size: 1.5em;
}

.newsletter-card-content{
    color: var(--offWhite);
}