.form-content{
    margin: 20px 0;
}

.artist_name_field{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.label_items{
    color: var(--light-gray);
    font-size: 12px;
    font-weight: 500;
}

.inp {
    height: 50px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    outline: none;
    padding: 0 20px;
    color: #fff;
    font-weight: 600;
}

.inp-field-containers{
    display: flex;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: flex-end;
}

.inp-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
}

.btn-container{
    display: flex;
    gap: 20px;
}

.btn-container button{
    min-width: 100px;
}

.agreement-details{
    height: 400px;
    background: rgba(255, 255, 255, 0.1);
    overflow: auto;
    padding: 20px;
    border-radius: 10px;
}

.users-agreement-read{
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    color: #ff8303;
    display: flex;
    align-items: center;
    padding: 10px 10px;
}

.greetings-message{
    padding: 20px;
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}

.Mui-active circle, .Mui-completed path{
    color: #ff8303;
}

.Mui-disabled circle {
    color: rgba(255, 255, 255, 0.1);
}

.Mui-active > .css-8t49rw-MuiStepConnector-line {
    border-color: #ff8303;
}

.Mui-active > .css-z7uhs0-MuiStepConnector-line{
    border-color: #ff8303;
}

.artist-data-main-section{
    display: flex;
    gap: 10px;
}