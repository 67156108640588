.faq-section-head-text{
    font-size: 24px;
    font-family: var(--title-font);
    letter-spacing: 1px;
    margin: 0 0 20px 0;
    color: var(--primary);
}

.rokk-app-faq-questions-container{
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin: 10px 0;
}


@media screen and (max-width: 1000px) {
    .rokk-faq-container{
        padding: 0 20px;
    }
}