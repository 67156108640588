@font-face {
  font-family: 'header-font';
  src: url('./fonts/TitlingGothicFB/TitlingGothicFBComp-Medium.ttf');
}

/* Font imports */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@100;200;300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

:root {
  --primary: #FF8303;
  --secondary: #FBC185;
  --offWhite: #ffffff80;
  --white: #ffffff;
  --black: #000000;
  --gray: #DFDFDF;
  --primary-color: #007bff; /* blue */
  --secondary-color: #6c757d; /* gray */
  --success-color: #28a745; /* green */
  --danger-color: #dc3545; /* red */
  --warning-color: #ffc107; /* yellow */
  --info-color: #17a2b8; /* teal */
  --light-color: #CBCBCB; /* light gray */
  --dark-color: #232323; /* gray shades */
  --dark-gray: #111111;
  --light-gray: rgba(255, 255, 255, 0.2);
  --head-text: "header-font";
  --standard-size: 1;
  --global-padding: 120px;
  --title-font: 'header-font';
}

html {
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  color: var(--white);
}

body {
  margin: 0;
  font-family: 'Poppins', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #101010;
  color: var(--white);
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* For WebKit-based browsers */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #1f1f1f;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}

#outlined-textarea{
  color: var(--white);
}

@media screen and (max-width: 1000px){
  :root {
    --global-padding: 20px;
  }
}
