.footer-signup-container{
    background: linear-gradient(180deg, rgba(16, 16, 16, 0.4) 0%, #101010 100%),url("../assets/footer_bg_rokk_app.png");
    min-height: 700px;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.footer-head-text{
    display: flex;
    flex-direction: column;
    font-family: var(--title-font);
    align-items: center;
    font-size: 4em;
}

.footer-content{
    padding: 0px var(--global-padding);
    font-size: 0.9em;

}

.footer-content-logo-socials{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px 0;
    align-items: center;
}

.footer-content-logo-socials img{
    width: 100px;
}

.social-media-icons-container{
    display: flex;
    gap: 20px;
    margin: 10px 0;
}

.social-media-icons-container li {
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.social-media-icons-container li img{
    width: 20px;
    opacity: .5;
}

.social-media-icons-container li:hover{
    background: rgba(0,0,0,0.7);
}


.footer-nav-links{
    padding: 40px 20px;
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    margin: 40px 0 20px 0;
    flex-wrap: wrap;
}

.footer-nav-links div, .terms-and-cond div {
    display: flex;
    gap: 50px;
}
.footer-nav-links div li, .terms-and-cond div li {
    display: block;
    color: var(--offWhite);
    cursor: pointer;
}

.footer-nav-links div li:hover, .terms-and-cond div li:hover {
    display: block;
    color: #ffffff;
}

.footer-content-disclaimer{
    padding: 20px 0 50px 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    line-height: 25px;

}

.terms-and-cond{
    padding: 50px 0;
    flex-wrap: wrap;
}

@media screen and (max-width: 1000px) {
    .footer-head-text{
        font-size: 6vw;
        text-align: center;
    }
}

@media screen and (max-width: 700px) {
    .footer-signup-container{
        font-size: 0.8em;
    }
    .footer-nav-links div{
        flex-direction: column;
        gap: 10px;
    }
    .footer-nav-links{
        padding: 30px 5px;
    }
    .terms-and-cond{
        justify-content: center;
        gap: 20px;
    }
    .footer-content-logo-socials{
        justify-content: center;
        gap: 20px;
    }
}

  .icon {
    margin-left: 5px;
    /* visibility: hidden; */
  }
  
  .company-information:hover .icon {
    color: var(--primary);
    /* visibility: visible; */
    margin-left: 10px;
    transition: all 0.3s ease-in-out;
  }
  