.home-header-container{
    height: 100vh;
    height: 100svh;
    background-color: #fff;
    background: linear-gradient(180deg, rgba(16, 16, 16, 0) 56.3%, #101010 85.07%), linear-gradient(247.38deg, rgba(16, 16, 16, 0) 0%, #101010 86.72%, #101010 100%), url("../../assets/main_bg_rokk_app.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    padding: 20px var(--global-padding);
    /* position: absolute;
    top: 0; */
}

.head-text{
    font-size: 4.5em;
    font-weight: 400;
    margin-top: 150px;
    font-family: var(--title-font), sans-serif;
    line-height: 1em;
    letter-spacing: 1px;
}

.head-txt-primary{
    background: linear-gradient(to right, #FF8303, #FBC185);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.head-txt-off-white{
    background: linear-gradient(to right, #CBCBCB, #FFFFFF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.head-txt-gray{
    background: linear-gradient(#FFFFFF, #CBCBCB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.head-desc-main{
    display: flex;
    flex-direction: column;
    margin: 50px 0;
    color: rgba(255, 255, 255, 0.8);
}

.screens-bg{
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    padding-bottom: 56.26%;
    margin-top: -25vh;
}

.screens-bg img{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
}

.video-wrapper{
    position: absolute;
    top: 5.5%;
    left: 10.2%;
    width: 89%;
    height: 100%;
    display: flex;
    align-items: center;
}

.video-wrapper video {
    max-width: 69.95%;
    max-height: 100%;
    object-fit: cover;
    aspect-ratio: 3/1.7;
}

.play-pause-icon-video-playback, .mute-unmute-btn{
    position: absolute;
    top: 27%;
    left: 12%;
}
.mute-unmute-btn{
    margin-left: 60px;
}


.pricing-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    /* margin-top: -200px; */
    margin-top: 10vw;
    padding: 0 60px;
}

.pricing-section-header {
    display: flex;
    flex-direction: column;
    font-size: 3.5em;
    font-family: var(--title-font);
    line-height: 1em;
    letter-spacing: 1px;
}

.plans-card{
    position: relative;
    padding: 5px;
}

.newsletter-and-card-section{
    min-height: 400px;
    background: linear-gradient(180deg, #000000 0%, rgba(16, 16, 16, 0) 100%);
    padding: 0 var(--global-padding);
    margin: 150px 0 0 0;
    /* position: absolute; */
    gap: 80px;
}

.newsletter-container{
    background: linear-gradient(263.97deg, #000000 42.67%, rgba(0, 0, 0, 0.3) 100%), url("../../assets/subscribe_to_newsletter_bg_rokk_app.png");
    padding: 20px 102px 20px 72px;
    background-position: top left;
    margin-top: -100px;
    position: relative;
    min-height: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    border-radius: 10px;
}

.newsletter-section-header{
    font-size: 4em;
    font-family: var(--title-font);
    letter-spacing: 1px;
    line-height: 0.8em;
}

.newsletter-form-section{
    min-width: 30%;
}

.newsletter-form-section input[type="text"]{
    padding: 10px 20px;
    background: transparent;
    border: 2px solid #FFFFFF;
    outline: none;
    font-family: "Poppins";
    color: var(--white);
    border-radius: 5px;
}

.newsletter-form-section input[type="email"], select{
    padding: 12px 22px;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    outline: none;
    font-family: "Poppins";
    color: var(--white);
    border-radius: 5px;

}

select::-ms-expand {
    margin-right: 10px;
  }

.newsletter-form-section button {
    padding: 14px 0;
}

.newsletter-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 30px;
    font-size: 13px;
}

.download-app-container{
    min-height: 700px;
    margin-top: 100px;
    background: #000 url("../../assets/download_platform_bg_rokk_app.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.download-btn{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}
.download-btn img {
    cursor: pointer;
    border-radius: 5px;
}

@media screen and (max-width: 1000px) {
    .pricing-section{
        padding: 30px 20px;
    }
    .newsletter-container{
        padding: 30px 10px;
        justify-content: center;
    }
}

@media screen and (max-width: 700px) {
    .head-text{
        margin-top: 80px;
        font-size: 3em;
    }
    .newsletter-container{
        padding: 30px 10px;
        justify-content: center;
    }
    .pricing-section-header{
        font-size: 10vw;
    }
    .newsletter-section-header{
        font-size: 10vw;
        text-align: center;
    }
    .play-pause-icon-video-playback, .mute-unmute-btn{
        display: none;
    }
}

/* Fire Border Effect */
.animated-border-svg{
    height: 0;
}
  
.animated-border, .animated-border-modal {
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 30px);
    top: -12px;
    left: -30px;
    /* Firefox */
    filter: url(#animated-border-filter) blur(1px);
    /* webkit */
    -webkit-filter: url(#animated-border-filter) blur(1px);
    z-index: -999;
    padding: 10px;
    display: none;
}

.plans-cards .animated-border {
    display: block;
}
  
.plans-card:first-child .animated-border {
    display: block;
}

.plans-cards-container{
    transition: transform 0.2s ease-in-out;
}

.plans-card:hover .plans-cards-container{
    background: linear-gradient(rgb(35, 35, 35) 60%, rgb(11, 11, 11) 100%);
    transform: scale3d(1.06, 1.06, 1.06);
}

.plans-card:first-child .plans-cards-container{
    background: linear-gradient(rgb(35, 35, 35) 60%, rgb(11, 11, 11) 100%);
    transform: scale3d(1.06, 1.06, 1.06);
}


.animated-border:before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: -12px;
    /* border: 20px solid #FFF; */
    border-radius: 25px;
    box-shadow: 0 0 50px #FF8303, inset 0 0 50px #FF8303;
    -webkit-animation: animateBorder 5s linear infinite; /* Safari 4+ */
    -moz-animation: animateBorder 5s linear infinite; /* Fx 5+ */
    -o-animation: animateBorder 5s linear infinite; /* Opera 12+ */
    animation: animateBorder 5s linear infinite;
}

.animated-border:after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(#FFF, #FF8303);
    border: 20px solid transparent;
    border-radius: 25px;
    box-shadow: 0 0 10px #FF8303, inset 0 0 20px #FF8303;
    box-sizing: border-box;

}

@-webkit-keyframes animateBorder {
    0% {
        -webkit-box-shadow: 0 0 25px #FF8303, inset 0 0 25px #FF8303;
    }
    20% {
        -webkit-box-shadow: 0 0 30px #FF8303, inset 0 0 30px #FF8303;
    }
    40% {
        -webkit-box-shadow: 0 0 20px #FF8303, inset 0 0 20px #FF8303;
    }
    60% {
        -webkit-box-shadow: 0 0 40px #FF8303, inset 0 0 40px #FF8303;
    }
    80% {
        -webkit-box-shadow: 0 0 50px #FF8303, inset 0 0 50px #FF8303;
    }
    100% {
        -webkit-box-shadow: 0 0 25px #FF8303, inset 0 0 25px #FF8303;
    }
}

@-moz-keyframes animateBorder {
    0% {
        -webkit-box-shadow: 0 0 25px #FF8303, inset 0 0 25px #FF8303;
    }
    20% {
        -webkit-box-shadow: 0 0 30px #FF8303, inset 0 0 30px #FF8303;
    }
    40% {
        -webkit-box-shadow: 0 0 20px #FF8303, inset 0 0 20px #FF8303;
    }
    60% {
        -webkit-box-shadow: 0 0 40px #FF8303, inset 0 0 40px #FF8303;
    }
    80% {
        -webkit-box-shadow: 0 0 50px #FF8303, inset 0 0 50px #FF8303;
    }
    100% {
        -webkit-box-shadow: 0 0 25px #FF8303, inset 0 0 25px #FF8303;
    }
} 

