.App{
    max-width: 2000px;
    margin: 0 auto;
}

.flex {
    display: flex;
}

.flex-dir-col {
    flex-direction: column;
}

.jst-con-cen {
    justify-content: center;
}

.jst-con-spc-btw{
    justify-content: space-between;
}

.aln-items-cnt{
    align-items: center;
}

.position-absolute{
    position: absolute;
}

.gp-10{
    gap: 10px;
}

.gp-20{
    gap: 20px;
}

.wdt-100-perc{
    width:  100%;
}

.ht-40{
    height: 40px;
}

.mt-10{
    margin-top: 10px;
}

.line-ht-1{
    line-height: 1em;
}

.font-sz-small {
    font-size: 0.8em;
}

.font-sz-medium {
    font-size: 1.2em;
}

.flex-wrap{
    flex-wrap: wrap;
}