.artists-benefits-cards-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    margin: 50px 0;
    padding: 0 var(--global-padding);
}

.artists-benefits-card {
    background: linear-gradient(215.36deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
    padding: 50px 20px 20px 20px;
    font-family: var(--title-font);
    font-size: 2em;
    color: rgba(255, 255, 255, 0.5);
    line-height: 1em;
}

.artist-page-main-sec{
    padding: 50px var(--global-padding);
    display: flex;
    gap: 20px;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
}

.artist-page-main-sec img {
    width: 400px;
}

.artist-page-head-text{
    margin: 20px 0;
    /* font-size: 4em; */
}

@media screen and (max-width: 1000px) {
    .artist-page-main-sec img {
        /* display: none; */
        width: 100%;
        min-width: 0;
    }
}