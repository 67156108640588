.navbar-container{
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 10px 40px;
    align-items: center;
    background: #181818;
    position: sticky;
    box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
    top: 0;
    z-index: 999;
}

.nav-logo {
    width: 250px;
}

.nav-logo img{
    width: 100px;
    cursor: pointer;
    margin-top: 7.5px;
}

.nav-links{
    display: flex;
    gap: 40px;
    justify-content: center;
    flex: 1;
}
.nav-links li{
    display: block;
    color: var(--white);
    opacity: .5;
    cursor: pointer;
    font-weight: 500;
}
.nav-links li:hover{
    opacity: 1;
}

.menu-icon{
    display: none;
    padding: 10px;
    background: #1a1a1a;
}

#activeLink {
    color: var(--primary);
    opacity: 1;
}

.auth-section, .auth-section-nav-bar{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    gap: 10px;
}
.btn{
    padding: 10px 20px;
    border: none;
    color: var(--white);
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.btn img{
    width: 20px;
    margin: 0 -5px;
}
.secondary-btn{
    background: var(--light-gray);
}
.primary-btn{
    background: var(--primary);
}
.primary-border-btn {
    border: 1px solid var(--primary);
    background: transparent;
    color: var(--primary);
    padding: 12px 30px;
}

.secondary-btn-light{
    background: var(--light-gray);
}



@media screen and (max-width: 1100px) {
    .navbar-container{
        padding: 10px 20px;
    }
    .nav-links{
        display: none;
    }
    .menu-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff8303;
        cursor: pointer;
        border-radius: 5px;
    }
    .auth-section-nav-bar {
        display: none;
    }

}

.sidebar-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 200px;
    height: 100%;
    background-color: var(--black);
    z-index: 999;
    padding: 40px;
}

.sidebar-links {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sidebar-links li {
    color: var(--white);
    opacity: .5;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 10px;
    display: block;
    transition: opacity .3s ease-in-out;
}

.sidebar-links li:hover {
    opacity: 1;
}

#activeLink {
    color: var(--primary);
    opacity: 1;
}

