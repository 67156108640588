
.pricing-page-header-container{
    height: 80vh;
    height: 80svh;
    min-height: 650px;
    background-color: #fff;
    background: linear-gradient(180deg, rgba(16, 16, 16, 0) 56.3%, #101010 100%), linear-gradient(247.38deg, rgba(16, 16, 16, 0) 0%, #101010 86.72%, #101010 100%), url("../../assets/pricing_page_bg_rokk_app.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    padding: 0 var(--global-padding);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.newsletter-container-pricing-page{
    background: linear-gradient(263.97deg, #000000 42.67%, rgba(0, 0, 0, 0.3) 100%), url("../../assets/subscribe_to_newsletter_bg_rokk_app.png");
    padding: 20px 102px 20px 72px;
    background-position: top left;
    margin-top: -100px;
    position: relative;
    min-height: 300px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    border-radius: 10px;
}

.plans-card-pricing-page{
    padding: 0 var(--global-padding);
}


.newsletter-content{
    flex: .5;
    color: var(--offWhite);
}
@media screen and (max-width: 1000px) {
    .plans-card-pricing-page{
        margin-top: -100px;
    }
    .newsletter-container-pricing-page{
        padding: var(--global-padding);
        display: flex;
        justify-content: center;
        margin: var(--global-padding) 0;
    }
    .newsletter-content{
        flex: 1;
    }
}