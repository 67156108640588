.plans-cards-wrapper {
    margin: 20px 0 50px 0;
    display: grid;
    padding: 0 var(--global-padding);
    width: calc(100% - var(--global-padding));
    grid-template-columns: repeat(auto-fit, minmax(360px, 390px));
    gap: 20px; /* Adds some spacing between grid items */
    position: relative;
    justify-content: center;
    z-index: 100;
  }
  

.plans-cards-container{
    background: linear-gradient(215.36deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
    min-height: 300px;
    padding: 20px;
    max-width: 330px;
    display: flex;
    flex-direction: column;
    position: relative;
    /* overflow: hidden; */
    z-index: 999;
    height: calc(100% - 60px);
    border-radius: 10px;
}

.plans-cards-title{
    font-size: 2em;
    font-weight: 600;
    margin-top: -12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.plans-cards-price-stroke{
    font-size: 1.7em;
    font-weight: 600;
    color: var(--primary);
    padding: 9px 0;
}

.plans-cards-per-month{
    align-self: center;
    font-size: 1.1em;
    color: var(--gray);
}

.plans-cards-benefits-section{
    margin: 16px 0 -3px 0;
    color: var(--gray);
    gap: 10px;
    /* padding: 0 20px; */
    flex: 1;
}

.plans-cards-benefits-section li {
    font-size: 14px;
    display: block;
}


.plans-cards-container .plans-button 
{ 
    background: var(--light-gray);
}

.activeCard .plans-button { 
    background: var(--primary);
}

